import { Navigate } from "react-router-dom";

const Public = ({isLoggedIn, children}: { isLoggedIn: boolean, children: any }) => {
    if (isLoggedIn) {
        return <Navigate to="/dashboard" replace />;
    }
    return children;
};

export default Public
